import {API, XHR_REQUEST} from "@/api/backend";

const _defaultCategoryInformation = {
  name: '',
  active: false,
  internal: false,
  description: {
    short: '',
    long: ''
  },
  seo: {
    title: '',
    description: '',
    h1: ''
  }
}

const state = {
  categoryInformation: { ..._defaultCategoryInformation },
  apiStatus: null,
  postStatus: null,
}

const mutations = {
  updateCategoryField(state, { field, value }) {
    try {
      switch ( typeof value) {
        case 'object' : state.categoryInformation[field] = { ...state.categoryInformation[field], ...value }; break;
        default: state.categoryInformation[field] = value; break;
      }
    } catch (e) {
      console.error(e)
      return false;
    }
  },
  setApiStatus(state, status) {
    state.apiStatus = status;
  },
  setPostStatus(state, status) {
    state.postStatus = status;
  },
  setCategoryInformation(state, {categoryInformation, apiStatus}) {
    state.categoryInformation = categoryInformation;
    state.apiStatus = apiStatus;
  },
  resetCategoryInfo(state) {
    state.categoryInformation = { ..._defaultCategoryInformation };
    state.apiStatus = null;
    state.postStatus = null;
  },
}

const actions = {
  async fetchCategoryInformation({commit, state}, categoryId ){
    try {
      commit('setApiStatus', 'loading');
      const resp = (await API.get(`/product-categories/${categoryId}`));
      commit('setCategoryInformation', { categoryInformation: { ...state.categoryInformation, ...resp.data } , apiStatus: 'success' });
    } catch (e) {
      commit('setCategoryInformation', { categoryInformation: {}, apiStatus: 'error' });
    }
  },
  async submitForm({commit, rootState}, categoryId) {
    commit('setPostStatus', 'loading');
    const API = XHR_REQUEST(`/product-categories${categoryId}?locale=pl`, categoryId ? 'put': 'post', rootState.Auth.token);

    try {
      await API.send(JSON.stringify(state.categoryInformation));
      commit('setPostStatus', 'success');
    } catch (e) {
      console.log(e)
      commit('setPostStatus', 'error');
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
