import {API} from "@/api/backend";

const state = {
  items: [],
  itemsToAdd: [],
  postStatus: null,
  errorMessage: '',
  paginationLast: 1
}

const mutations = {
  setItems(state, payload = []) {
    console.log(payload[0]?.url)
    console.log(state.items[0]?.url)
    state.items = payload
    console.log(state.items[0]?.url)
  },
  setItemsToAdd(state, payload = []) {
    state.itemsToAdd = [...payload]
  },
  setPostStatus(state, status) {
    state.postStatus = status;
  },
  setErrorMessage(state, message) {
    state.errorMessage = message
  },
  setPagination(state, payload) {
    state.paginationLast = payload
  }
}

const actions = {
  async getMediaItems({commit}, page = 3){
    const getPageNumber = elem => {
      try {
        return elem.split('&page=')[1]
      } catch (e) {
        console.error(e)
        return 1
      }
    }
    try {
      const resp = await API.get(`/media-objects?itemsPerPage=50&page=${page}`)
      const images = resp.data['hydra:member'].map( photo => photo.image )
      console.log(images[0])
      const details = resp.data['hydra:view']
      const lastPage = getPageNumber(details['hydra:last'])

      commit('setPagination', lastPage ?? 1)
      commit('setItems', images )
    } catch (e) {
      console.error(e)
    }
  },
  async submitNewItems({rootState, state, commit, dispatch }) {
    try {
      let formData = new FormData()

      state.itemsToAdd.forEach( item => {
        formData.append('file', item)
        formData.append('type', 'picture')
        formData.append('object', 'Product')
      })

      try {
        await API.post(`/media-objects`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${rootState.Auth.token}`
          }
        })
      } catch (e) {
        console.log(e)
      }
      commit('setPostStatus', 'success');
      commit('setItemsToAdd')
      dispatch('getMediaItems')
      return true
    } catch (e) {
      commit('setPostStatus', 'error');
      commit('setErrorMessage', e.response.data['hydra:description'])
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
